import { setAlert } from "./alert";
import { removeLoading, setLoading } from "./loading";
import {
    OPENAI_QUERY_SET,
    ENTITY_CREATED,
    ENTITY_UPDATED,
    MEDIA_RECEIVED_LINKED_ENTITIES,
    ENTITY_OPENAI_QUIERIES_RECEIVED
} from "../types";
import { setAuthHeadersAsJson } from "../../utils";
const WAW_API_ROOT = '/api';

export const generate = (prompt, intent, entityId, key) => async dispatch => {
    switch (intent) {
        case 'CREATE':
            dispatch(generateCREATE(prompt, key));
        case 'ASK':
            dispatch(generateASK(prompt, entityId, key));
        case 'FIND':
            dispatch(generateFIND(prompt, entityId, key));
    }
}

export const generateASK = (prompt, entityId, key) => async dispatch => {
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))

    try {
        const url = `/openai/entity/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "ASK", temperature: 0.3 })
        });
        if (response.ok) {
            const responseData = await response.json();
            console.log(responseData)
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};


export const generateFIND = (prompt, entityId, key) => async dispatch => {
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))

    try {
        const url = `/openai/entity/${entityId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "FIND", temperature: 0.3 })
        });
        if (response.ok) {
            const responseData = await response.json();
            console.log(responseData)
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};


export const generateCREATE = (prompt, key) => async dispatch => {
    if (!prompt || prompt.length <= 5) {
        dispatch(setAlert(
            "AI Assistant Prompt is required. Write several words or " +
            "sentences describing what you need assitance with", 'error', 5000));
        return;
    }
    dispatch(setLoading(5000))

    try {
        const url = `/openai/entity`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({ prompt: prompt, intent: "CREATE", temperature: 0.3 })
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query });
            dispatch({ type: ENTITY_CREATED, payload: responseData.entity, key: key });
            dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: { entities: [responseData.entity] } });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getOpenAIQuery = (queryId, entitySlug, entityId) => async dispatch => {
    if (!queryId) {
        console.log('getOpenAIQuery query id cannot be null', queryId)
        return;
    }
    try {
        const url = `/openai/entity/${entityId}/query/${queryId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: OPENAI_QUERY_SET, query: responseData.query, slug: entitySlug, id: entityId });
            if (responseData.entity) {
                dispatch({ type: ENTITY_UPDATED, payload: responseData.entity });
                dispatch({ type: MEDIA_RECEIVED_LINKED_ENTITIES, payload: { entities: [responseData.entity] } });
            }
        }
    } catch (error) {
        console.log(error)
    }

};

export const getOpenAIQueries = (entityId) => async dispatch => {
    if (!entityId) {
        console.log('getOpenAIQueries entityId cannot be null', entityId)
        return;
    }
    try {
        const url = `/openai/entity/${entityId}/queries`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            const responseData = await response.json();
            dispatch({ type: ENTITY_OPENAI_QUIERIES_RECEIVED, payload: responseData, entityId: entityId });
        }
    } catch (error) {
        console.log(error)
    }

};