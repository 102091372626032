export const setAuthHeaders = headersObject => {
    let headers = { ...headersObject };
    if (localStorage.jwt_access_token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${localStorage.jwt_access_token}`
        };
    }
    return headers;
};

export const setAuthHeadersAsAmadeus = headersObject => {
    let headers = {
        ...headersObject,
        'Content-Type': 'application/json'
    };
    var access = process.env.REACT_APP_AMADEUS_API_KEY;
    if (access) {
        headers = {
            ...headers,
            Authorization: `Bearer ${access}`
        };
    }
    return headers;
};

export const setAuthHeadersAsJson = headersObject => {
    let headers = {
        ...headersObject,
        'Content-Type': 'application/json'
    };
    var access = localStorage.getItem('jwt_access_token');
    if (access) {
        headers = {
            ...headers,
            Authorization: `Bearer ${access}`
        };
    }
    return headers;
};

const getCookie= name => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();

            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }

    return cookieValue;
}

export const setAuthHeadersAsJsonWithCsrf = headersObject => {
    let headers = {
        ...headersObject,
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
    };
    if (localStorage.jwt_access_token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${localStorage.jwt_access_token}`
        };
    }
    return headers;
};
