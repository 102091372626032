const css = (theme) => ({
  appbar: {
    backgroundColor: '#fff !important',
    color: '#263238 !important',
    borderBottom: '1px solid rgba(0,0,0,.05)',
  },
  logoBox: {
    alignItems: 'left',
    display: 'flex',
    gap: '20px',
  },
  logoImg: {
    height: '48px',
    width: '48px',
    display:'inline-flex'
  },
  logoText: {
    color:'#0a1425',
    fontWeight:700,
    display:'inline-flex',
    textTransform:'uppercase',
    fontFamily: '"ColfaxAI",Helvetica,sans-serif',
    fontSize: '16px'
  },
  logoImgSm: {
    width: '100%',
    maxHeight: '30px',
  },
  logo: {
    display: { xs: 'none', md: 'flex' },
  },
  snsIcon: {
    color:'#263238',
    fontSize:'22px',
    "&:hover": {
      color: '#485D7C',
    },
  },
});
export default css;
