import {NOTIFICATIONS_GET_UNREAD} from '../types';

const initialState = {
  unreadNotifications: null,
  unreadNotificationsLoading: false
};


const getUnreadNotifications = (state, payload) => {
  return {
    ...state,
    unreadNotifications:payload,
    unreadNotificationsLoading:false
  };
};

const inspirations =  (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case NOTIFICATIONS_GET_UNREAD:
      return getUnreadNotifications(state, payload);
    default:
      return state;
  }
};

export default inspirations;
