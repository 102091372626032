export const ENTITIES_GET_BY_QUERY = 'ENTITIES_GET_BY_QUERY';
export const ENTITIES_SET_LOADING = 'ENTITIES_SET_LOADING';

export const ENTITY_RETRIEVED = "ENTITY_RETRIEVED";
export const ENTITY_CREATED = "ENTITY_CREATED";
export const ENTITY_UPDATED = "ENTITY_UPDATED";
export const ENTITY_DELETED = "ENTITY_DELETED";




