import {Grid} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from '@material-ui/icons/Twitter';

const Social = (props) => {
    const {classes} = props;
    return (
        <Grid item container spacing={2}>
            <Grid
                item
                component={"a"}
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/whyandwheredotcom"
            >
                <FacebookIcon className={classes.snsIcon}/>
            </Grid>
            <Grid
                item
                component={"a"}
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.instagram.com/whyandwhere/"
            >
                <InstagramIcon
                    className={classes.snsIcon}
                />
            </Grid>
            <Grid
                item
                component={"a"}
                target="_blank"
                rel="noreferrer noopener"
                href="https://mobile.twitter.com/WhyAndWhere_com"
            >
                <TwitterIcon className={classes.snsIcon}/>
            </Grid>
        </Grid>
    );
};

export default Social;