import {v4 as uuid} from "uuid";
import {REMOVE_ALERT, SET_ALERT} from '../types';

export const setAlert = (msg, alertType, timeout = 5000, isSilent = false) => dispatch => {
    if (isSilent) {
        console.log(msg);
        return;
    }
    const id = uuid;
    dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id }
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};