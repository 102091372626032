import {v4 as uuid} from "uuid";
import {REMOVE_LOADING, SET_LOADING} from '../types';

export const setLoading = (timeout = 5000) => dispatch => {
    const id = uuid;
    dispatch({
        type: SET_LOADING,
        payload: { id }
    });

    //setTimeout(() => dispatch({ type: REMOVE_LOADING, payload: id }), timeout);
};

export const removeLoading = (timeout = 5000) => dispatch => {
    const id = uuid;
    dispatch({
        type: REMOVE_LOADING,
        payload: { id }
    });

    //setTimeout(() => dispatch({ type: REMOVE_LOADING, payload: id }), timeout);
};