import {setAuthHeadersAsJson, WAW_API_ROOT} from "../../utils";

export const processError = (source, error, type) => async dispatch => {
    var isDebug = process.env.REACT_APP_DEBUG;
    if (isDebug) {
        console.log("Debugging information ", source, error, type);
    }
    try {
        const url = `/admin/notify`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({'error': error, 'source': source, 'type': type})
        });
    } catch (error) {
        console.log('Processing error ', error)
    }
};
