import {setAuthHeadersAsJson} from "../../utils";
import {
    AIRPORT_ENTITY_ADDED,
    AIRPORT_ENTITY_DELETED,
    AIRPORT_ENTITY_GET_ALL,
    AIRPORT_GET_TO,
    AIRPORT_GET_FROM,
    AIRPORT_PROFILE_ADDED,
    AIRPORT_PROFILE_DELETED,
    AIRPORT_PROFILE_GET_ALL
} from "../types";
import {setAlert} from "./alert";
import {removeLoading, setLoading} from "./loading";

const WAW_API_ROOT = '/api';
var Amadeus = require('amadeus');
var amadeus = new Amadeus({
    clientId: 'wkZGhcrSRILxNlv2x4qxRvOF9TykmX1P',
    clientSecret: 'dcdFQyyFEE5l9OYi'
});


export const addAirportToProfile = (username, airport) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/from_airports`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body:  JSON.stringify(airport)
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_PROFILE_ADDED, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const deleteAirportFromProfile = (username, airport_id) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/airports/${airport_id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_PROFILE_DELETED, payload: responseData});
        } else {
            dispatch(setAlert(response.statusText, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getProfileAirports = (username) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/profiles/${username}/airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_PROFILE_GET_ALL, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const bak_getSupportedAirports = () => async dispatch => {
    dispatch(setLoading(5000))
    try {

        const url = `/airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_GET_FROM, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getFromAirports = () => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/from_airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_GET_FROM, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getToAirports = (query) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/to_airports?q=${query}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_GET_TO, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};


export const bak_getToAirports = (keyword, direction) => async dispatch => {
    dispatch(setLoading(5000))
    amadeus.referenceData.locations.get({
        keyword: keyword,
        subType: 'AIRPORT'
    }).then(function (response) {
        dispatch({type: AIRPORT_GET_TO, payload: response.data, direction: direction});
    }).catch(function (error) {
        console.log('error', error); //=> The response object with (un)parsed data
        console.log(error.code); //=> A unique error code to identify the type of error
        dispatch(setAlert(error.code, 'error', 5000));
    });
    dispatch(removeLoading(5000))
};

export const addAirportToEntity = (entity_slug, airport) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${entity_slug}/airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(airport)
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_ENTITY_ADDED, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const deleteAirportFromEntity = (entity_slug, airport) => async dispatch => {
    if (!airport) return;
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${entity_slug}/airports/${airport.direction}/${airport.code}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_ENTITY_DELETED, payload: responseData});
        } else {
            dispatch(setAlert(response.statusText, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getEntityAirports = (entity_slug) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/entities/${entity_slug}/airports`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: AIRPORT_ENTITY_GET_ALL, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};
