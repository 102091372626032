import {setAuthHeaders, setAuthHeadersAsJson} from "../../utils";
import {ENTRY_CREATED, ENTRY_GET_ALL} from "../types";
import {setAlert} from "./alert";

const WAW_API_ROOT = '/api';

export const createCalEntry = (username, entry) => async dispatch => {
    try {
        const url = `/calendar/${username}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(entry)
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: ENTRY_CREATED, payload: responseData});
            dispatch(getCalEntries(username));
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const deleteCalEntry = (id, username) => async dispatch => {
    try {
        const url = `/calendar/${username}/${id}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders()
        });
        await response;
        if (response.status === '204') {
            //dispatch({type: ENTRY_DELETED, payload: responseData});
            dispatch(getCalEntries(username));
        } else {
            dispatch(setAlert(response.statusText, 'error', 5000));
        }
        /*if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }*/
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const getCalEntries = (username) => async dispatch => {
    try {
        const url = `/calendar/${username}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: ENTRY_GET_ALL, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};
