// eslint-disable-next-line
/*global google*/
import React, {Component, Fragment} from 'react';
//import SearchBar from 'material-ui-search-bar';
import {withStyles} from "@material-ui/core";

const styles =({
    root: {
        border: '1px solid rgba(0, 0, 0, 0.4)',
        height:'60px',
        marginTop:'40px',
        marginBottom:'40px',
        margin: '0 auto',
        width: '100%',
        "& input": {
            fontSize: "24px",
            color:'#0a1425',
        }
    },
    title: {
        fontSize: '42px',
        padding: '0',
        width:'100%',
        textAlign: 'center',
        fontFamily: "'PT Sans', sans-serif'",
        color: 'rgba(82, 103, 137, 1)',
        textTransform: 'uppercase',
        fontWeight:'700'
    },
    resize:{
        fontSize:50
    },
});


class SearchInputMain extends Component {
    handlePlaceSelect = () => {
        const location = this.autocomplete.getPlace();
        if (location)  {
            this.props.onSearch(location);
        } else {
            this.handleSearchRequest(this.state.query);
        }
    }

    handleSearchRequest = (term) => {
        console.log(term);
    }

    constructor(props) {
        super(props);
        this.state = {
            query: ''
        };
    }

    componentDidMount(){
        /*const options = {};
        if (google){
            this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autocomplete'),
                options,
            );
            //this.autocomplete.setFields(['address_components', 'formatted_address']);
            this.autocomplete.addListener('place_changed', this.handlePlaceSelect)
        }*/

    }

    render() {
        const {classes} = this.props;
        return (
            <Fragment>
            {/*  <SearchBar id="autocomplete"
                           placeholder="Explore 'Mountain View, CA', '1000 places to see before you die', 'cycling'"
                           value={this.state.query}
                           className={classes.root}
                           onChange={this.handlePlaceSelect}
                           onRequestSearch={this.handleSearchRequest}
                />*/}
            </Fragment>

        );
    }
}
export default withStyles(styles)((SearchInputMain));
