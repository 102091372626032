import {removeLoading, setLoading} from "./loading";
import {
    SEARCH_GET_GOOGLE_PLACES,
    SEARCH_GET_WIKI_PLACES,
    SEARCH_GET_WIKI_VOYAGE_PLACES,
} from "../types";
import {setAlert} from "./alert";
import {getPlaceFromGooglePlace} from "../../utils/utils"
import {Place} from "../../utils/Place";


export const getGooglePlaces = (googlePlace) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        var place = getPlaceFromGooglePlace(googlePlace)
        if (place) {
            dispatch({type: SEARCH_GET_GOOGLE_PLACES, place: place});
        }

    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};

export const getWikiPlaces = (lat, lng, radius=1000, googlePlace) => async dispatch => {
    dispatch(setLoading(5000))

    try {
        var params = {
            'origin': "*",
            "action": "query",
            "prop": "coordinates|pageimages|description|info|extracts&exintro",
            "inprop": "url",
            "pithumbsize": 450,
            "generator": "geosearch",
            "ggsradius": radius,
            "ggslimit": 10,
            "ggscoord": lat + "|" + lng,
            "format": "json",
        }

        var query = Object.keys(params).map(function (key) {
            return key + '=' + params[key];
        }).join('&');
        const response = await fetch(`https://en.wikipedia.org/w/api.php?${query}`, {
            method: 'GET',
            'Content-Type': 'application/json'
        });
        const responseData = await response.json();

        var places = responseData && responseData.query && responseData.query.pages ? responseData.query.pages : null;

        if (response.ok) {
            dispatch({type: SEARCH_GET_WIKI_PLACES, payload: places, googlePlace:googlePlace});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }

    } catch (error) {
        console.log(error)
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
}

export const getWikiVoyagePlace = (lat, lng, radius=1000, googlePlace) => async dispatch => {
    dispatch(setLoading(5000))
    try {
        var params = {
            'origin': "*",
            "action": "query",
            "prop": "coordinates|pageimages|description|info|extracts&exintro&explaintext",
            "inprop": "url",
            "pithumbsize": 650,
            "generator": "geosearch",
            "ggsradius": radius,
            "ggslimit": 10,
            "ggscoord": lat + "|" + lng,
            "format": "json",
        }

        var query = Object.keys(params).map(function (key) {
            return key + '=' + params[key];
        }).join('&');
        const response = await fetch(`https://en.wikipedia.org/w/api.php?${query}`, {
            method: 'GET',
            'Content-Type': 'application/json'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: SEARCH_GET_WIKI_VOYAGE_PLACES, payload: responseData, googlePlace:googlePlace});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }

    } catch (error) {
        console.log(error)
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
}
