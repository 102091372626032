import React from 'react';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, withStyles} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import styles from './styles';

const Sidebar = props => {
    const {classes, user} = props;

    return (
        <section className={classes.root}>
            <List component="div" disablePadding>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/notifications/@' + user.username}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <AccountBoxIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Notifications"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/@' + user.username + '/mywatch'}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Watched By Me"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={ '/profile/@' + user.username + '/created'}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Created By Me"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/@' + user.username + '/favorited'}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Favorited By Me"
                    />
                </ListItem>

                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/followedby/@' + user.username}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Users Followed By Me"
                    />
                </ListItem>

                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/following/@' + user.username}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Users Who Follow Me"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/myairports/@' + user.username }>
                    <ListItemIcon className={classes.listItemIcon}>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="My Home Airports"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={'/profile/myschedule/@' + user.username}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="My Schedule"
                    />
                </ListItem>
                {user && user.role === 'superadmin' && (
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to="/admin/users">
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Users"
                        />
                    </ListItem>
                )}

            </List>
            <Divider className={classes.listDivider}/>
            <List
                component="div"
                disablePadding
                subheader={
                    <ListSubheader className={classes.listSubheader}>
                        Support
                    </ListSubheader>
                }>
                <ListItem
                    className={classes.listItem}
                    component="a"
                    href="mailto:support@whyandwhere.com"
                    target="_blank">
                    <ListItemIcon className={classes.listItemIcon}>
                        <InfoIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Contact Us"
                    />
                </ListItem>
            </List>
        </section>
    );
}

const mapStateToProps = ({authState}) => ({
    user: authState.user
});
export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(Sidebar));
