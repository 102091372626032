// @ts-nocheck
import React, {Component, Fragment} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import store from './store';
//import { loadUser } from './store/actions';
import theme from './theme';
import {Alert, Loading} from './components';

import VideoPlayer from './videos/VideoPlayer';

import './assets/scss/index.scss';
import './assets/css/app.css';
import "./assets/css/video.css";

//import backgroundVideo from './assets/video/hjek-file_example_MOV_480_700kB.mov';
import backgroundVideo from './assets/video/foggy.mp4';

import {CssBaseline} from '@material-ui/core';
import {ParallaxProvider} from 'react-scroll-parallax';
import { Loader } from '@googlemaps/js-api-loader'
//import {GoogleOAuthProvider} from '@react-oauth/google';

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    id: "__googleMapsScriptId",
    libraries: ["places"]
});
loader.load()

class App extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <Provider store={store}>
                    <ParallaxProvider>
                        <ThemeProvider theme={theme}>
                            <CssBaseline/>
                            <Alert/>
                            <Loading/>
                            <VideoPlayer source={backgroundVideo} />
                        </ThemeProvider>
                    </ParallaxProvider>
            </Provider>
        );
    }
}

export default App;
