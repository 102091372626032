import {
    ENTITIES_GET_BY_QUERY,
    ENTITIES_SET_LOADING,
    ENTITY_DELETED,
    ENTITY_CREATED,
    ENTITY_RETRIEVED,
    ENTITY_UPDATED
} from '../types';

const initialState = {
    entities: {},
    queries: {}

};

const addEntity = (state, slug, id, payload, key) => {
    if (key) {
        if (!state['queries'].hasOwnProperty(key)) {
            state['queries'][key] = {}
            state['queries'][key]['entities'] = {}
            state['queries'][key]['count'] = 0
        }
        state['queries'][key]['count'] += 1
        state['queries'][key]['entities'][id] = slug;
    }
    return {
        ...state,
        entities: {
            ...state.entities,
            [id]: payload
        }
    }
}

const entityRetrieved = (state, payload) => {
    var slug = payload.slug;
    state['entities'][slug] = payload;
    return state;
}

const entityCreated = (state, payload, key) => {
    var slug = payload.slug;
    var id = payload.id;
    var newState = addEntity(state, slug, id, payload, key);
    newState['queries'][key]['loading'] = false;
    return newState;
};

const entityUpdated = (state, payload) => {
    var id = payload.id;
    var st = {
        ...state,
        entities: {
            ...state.entities,
            [id]: payload
        }
    }
    return st;
};

const entityDeleted = (state, id, slug) => {
    if (state['entities'].hasOwnProperty(id)) {
        delete state['entities'][id];
    }
    for (var key in state['queries']) {
        var query = state['queries'][key];
        if (query['entities'].hasOwnProperty(id)) {
            delete query['entities'][id];
            query['count'] -= 1;
        }
    }
    return state;
};

const getQueriedEntities = (state, payload, key) => {
    if (key) {
        if (!state['queries'].hasOwnProperty(key)) {
            state['queries'][key] = {}
            state['queries'][key]['entities'] = {}
            state['queries'][key]['count'] = 0
        }
        state['queries'][key]['count'] = payload.entitiesCount;
        for (var idx in payload.entities) {
            var entity = payload.entities[idx];
            state['queries'][key]['entities'][entity.id] = entity.slug;
            state['entities'][entity.id] = entity;
        }

    }
    return state;
};

const setEntitiesLoading = (state, payload, key) => {
    if (key) {
        if (!state['queries'].hasOwnProperty(key)) {
            state['queries'][key] = {}
            state['queries'][key]['entities'] = {}
            state['queries'][key]['count'] = 0
        }
        state['queries'][key]['loading'] = payload;
    }
    return state;
}

const entities = (state = initialState, action) => {
    const { type, payload, key, id, slug } = action;
    switch (type) {
        case ENTITY_CREATED:
            return entityCreated(state, payload, key);
        case ENTITY_UPDATED:
            return entityUpdated(state, payload);
        case ENTITY_DELETED:
            return entityDeleted(state, id, slug);
        case ENTITY_RETRIEVED:
            return entityRetrieved(state, payload);
        case ENTITIES_SET_LOADING:
            return setEntitiesLoading(state, payload, key);
        case ENTITIES_GET_BY_QUERY:
            return getQueriedEntities(state, payload, key);
        default:
            return state;
    }
};
export default entities;
