import {ADS_GET} from "../types";
const initialState = {
};

const getAds = (state, payload, key) => {
    let keyCount = key+"_Count";
    let keyLoading = key+"_Loading";
    var newState =  {...state};
    newState[key] = payload.entities;
    newState[keyCount] = payload.entitiesCount;
    newState[keyLoading] = false;
    return newState;
};


const ads = (state = initialState, action) => {
    const { type, payload, key } = action;
    switch (type) {
        case ADS_GET:
            return getAds(state, payload, key);
        default:
            return state;
    }
};
export default ads;