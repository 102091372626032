import {setAlert} from './alert';
import {
    TAG_ADD,
    TAG_DELETE,
    TAG_GET,
    TAG_GET_ALL,
    TAG_GET_POPULAR,
    TAGS_SET_LOADING
} from '../types';
import {setAuthHeadersAsJson} from "../../utils";

const WAW_API_ROOT = '/api';

export const getAllTags = () => async dispatch => {
    dispatch({type:TAGS_SET_LOADING, payload: true});
    try {
        const url = '/tags?state=4,5';
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: TAG_GET_ALL, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch({type:TAGS_SET_LOADING, payload: false});
};

export const getEntityTags = (slug) => async dispatch => {
    dispatch({type:TAGS_SET_LOADING, payload: true});
    try {
        const url = `/entities/${slug}/tags`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: TAG_GET, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch({type:TAGS_SET_LOADING, payload: false});
};

export const getPopularTags = () => async dispatch => {
    dispatch({type:TAGS_SET_LOADING, payload: true});
    try {
        const url = '/tags?state=5';
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET'
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: TAG_GET_POPULAR, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch({type:TAGS_SET_LOADING, payload: false});
};
export const addTag = (slug, tag) => async dispatch => {
    try {
        const url = `/entities/${slug}/tags`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify({tag})
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({ type: TAG_ADD, payload: responseData });
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};
export const deleteTag = (slug, tagId) => async dispatch => {
    try {
        const url = `/entities/${slug}/tags/${tagId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        if (response.ok) {
            dispatch({ type: TAG_DELETE, payload: tagId });
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};
