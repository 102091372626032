import React from 'react';
import {Box, Button, Container, Grid, Link, Typography, withStyles} from '@material-ui/core';
import Social from "../../../../components/Social";
import styles from "./styles";


import {subcribeForNewsletter} from "../../../../store/actions";
import {connect} from "react-redux";

const SubscribeForm = (props) => {
    const {classes} = props;
    const [email, setEmail] = React.useState(null);

    const submitForm = (evt) => {
        evt.preventDefault();
        subcribeForNewsletter(email)
    }

    return (
            <form onSubmit={submitForm}>
                <div className='subscription-form-field-group'>
                    <label htmlFor='emailField' className={classes.label}>Email</label>
                    <input type='email'
                           id='emailField'
                           onChange={setEmail}
                           value={email? email : ''}
                           className={classes.input}/>
                    <Button variant="outlined" size="small">Submit</Button>
                </div>
            </form>
    );
};

const Footer = (props) => {
    const {classes} = props;
    return (
        <footer>
            <Box
                px={{xs: 3, sm: 10}}
                py={{xs: 5, sm: 10}}
                className={classes.root}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.title}>Company</Box>
                            <Box className={classes.row}>
                                <Link href="/about" className={classes.link}>
                                    About
                                </Link>
                            </Box>
                            <Box className={classes.row}>
                                <Link href="/contact" className={classes.link}>
                                    Contact
                                </Link>
                            </Box>
                            <Box className={classes.row}>
                                <Link href="/terms" className={classes.link}>
                                    Terms
                                </Link>
                            </Box>
                            <Box className={classes.row}>
                                <Link href="/privacy" className={classes.link}>
                                    Privacy
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.title}>Social</Box>
                            <Box className={classes.row}>
                                <Social classes={classes}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.title}>Get Newsletter</Box>
                            <Box className={classes.row}>
                                <SubscribeForm classes={classes}/>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{
                            textDecoration: "none",
                            justifyContent: "center"
                        }}
                    >
                        <Typography className={classes.copyright}>
                            &copy;2022 Why And Where. All rights reserved.
                            <br/>
                            Designed by
                            <Link href="https://ailabconsulting.com/" target="_blank" className={classes.designed}>ailabconsulting.com</Link>
                            &copy;2022 All rights reserved
                        </Typography>

                    </Grid>
                </Container>
            </Box>
        </footer>
    );
};

const mapStateToProps = ({state}) => ({});

const mapDispatchToProps = {
    subcribeForNewsletter
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Footer));
