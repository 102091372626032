import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import styles from './styles.js';
import PropTypes from "prop-types";
import ButtonUpload from "../../Actions/ButtonUpload";

const ITEM_HEIGHT = 48;

const CardActions = props => {
    const {classes} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
    };
    const handleShare = () => {
    };

    return (
        <div className={classes.root}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{color:'#fff'}}/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                <MenuItem key={props.id + "upload"} >
                    <ButtonUpload id={props.id} slug={props.slug} type={props.type}/>
                </MenuItem>
                <MenuItem key={props.id + "delete"} onClick={handleDelete}>
                    Delete {props.type}
                </MenuItem>
                <MenuItem key={props.id + "share"} onClick={handleShare}>
                    Share
                </MenuItem>
            </Menu>
        </div>
    );
}


CardActions.propTypes = {
    id:PropTypes.string.isRequired,
    type:PropTypes.string.isRequired,
    slug:PropTypes.string.isRequired,
    isAdmin:PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
};
export default withStyles(styles)(connect(() => ({}), mapDispatchToProps)(CardActions));