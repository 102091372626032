import {
    GET_PROFILE_FOLLOWERS,
    GET_PROFILE_FOLLOWING,
    PROFILE_FOLLOW,
    PROFILE_GET,
    PROFILE_SET_PICTURE,
    PROFILE_UNFOLLOW
} from "../types";
import {setAlert} from "./alert";
import {setAuthHeaders, setAuthHeadersAsJson} from "../../utils";

const WAW_API_ROOT = '/api';
export const getProfile = (username) => async dispatch => {
    try {
        const url = `/profiles/${username}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: PROFILE_GET, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const followProfile = (username) => async dispatch => {
    try {
        const url = `/profiles/${username}/follow`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: PROFILE_FOLLOW, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const unfollowProfile = (username) => async dispatch => {
    try {
        const url = `/profiles/${username}/follow`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: PROFILE_UNFOLLOW, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const getProfileFollowedBy = (username) => async dispatch => {
    try {
        const url = `/profiles/${username}/followedby`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: GET_PROFILE_FOLLOWERS, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const getProfilesIFollow = (username) => async dispatch => {
    try {
        const url = `/profiles/${username}/following`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeaders()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: GET_PROFILE_FOLLOWING, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const setProfilePicture = (username, formData) => async dispatch => {
    try {
        const url = `/profiles/${username}/picture`;

        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeaders(),
            body: formData
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: PROFILE_SET_PICTURE, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};