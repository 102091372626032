import {GET_PROFILE_FOLLOWERS, GET_PROFILE_FOLLOWING, PROFILE_GET, PROFILE_SET, PROFILE_SET_PICTURE} from "../types";

const initialState = {
    selectedProfile: null,
    profileFollowers: [],
    profileFollowersCount: 0,
    profileFollowing: [],
    profileFollowingCount: 0,
    loading: false
};

const getProfile = (state, payload) => {
    return {
        selectedProfile:payload.profile,
        loading: false
    };
};

const setProfile = (state, profile) => {
    return {
        selectedProfile:profile,
    };
};

const setProfilePicture = (state, payload) => {
    return { ...state, selectedProfile: { ...state.selectedProfile, image: payload.profile.image} };
};

const getProfileFollowers = (state, payload) => {
    return {
        profileFollowers:payload.profiles,
        profileFollowersCount:payload.profilesCount,
        loading: false
    };
};

const getProfileFollowing = (state, payload) => {
    return {
        profileFollowing:payload.profiles,
        profileFollowingCount:payload.profilesCount,
        loading: false
    };
};

const profiles = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROFILE_GET:
            return getProfile(state, payload);
        case PROFILE_SET:
            return setProfile(state, payload);
        case GET_PROFILE_FOLLOWERS:
            return getProfileFollowers(state, payload)
        case GET_PROFILE_FOLLOWING:
            return getProfileFollowing(state, payload)
        case PROFILE_SET_PICTURE:
            return setProfilePicture(state, payload);

        default:
            return state;
    }
};
export default profiles