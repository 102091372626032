import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import {Sidebar} from "./Sidebar";

const styles = theme => ({
    drawer: {
        width: 250,
        [theme.breakpoints.up('sm')]: {
            width: 350
        },
    },

    fullList: {
        width: "auto"
    },
    toolbar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 60
    },
    content: {
        display: "flex",
        fontSize: '1.5em'
    },
    farRightToggleButton: {
        position: "absolute",
        right: 2
    },
    drawerPaper: {
        borderRight: 0,
        zIndex: 1200,
        width: '271px',
        top: theme.topBar.height
    },
    sidebar: {
        width: '100%'
    },
});

class UserDrawer extends React.Component {
    render() {
        const {classes, username, open, toggleDrawer} = this.props;
        return (
            <div>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={toggleDrawer}
                    classes={{
                        paper: classes.drawer,
                    }}>
                    <div className={classes.toolbar}>
                        <Typography variant="h6" component="h2">
                            My Dashboard
                        </Typography>
                        <IconButton onClick={this.props.toggleDrawer} className={classes.farRightToggleButton}>
                            <Close/>
                        </IconButton>
                    </div>
                    <Divider/>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.props.toggleDrawer}
                        className={classes.content}
                        onKeyDown={this.props.toggleDrawer}>
                        <Sidebar className={classes.sidebar} username={username}/>
                    </div>
                </Drawer>
            </div>
        );
    }
}

UserDrawer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserDrawer);