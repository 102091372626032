export const WAW_API_ROOT = '/api';


export const getQueryLoading = (state, key) => {
    return state['queries'] && state['queries'].hasOwnProperty(key) ? state['queries'][key]['loading'] : false;
}

export const getQueryCount = (state, key) => {
    return state['queries'] && state['queries'].hasOwnProperty(key) ? state['queries'][key]['count'] : null;
}

export const getQueryEntities = (state, key) => {
    var result = null;
    if (state['queries'] && state['queries'].hasOwnProperty(key)) {
        result= [];
        for (var id in  state['queries'][key]['entities']) {
           result.push(state['entities'][id]);
        }

        return (
            result.sort((x, y) => {
                return new Date(x.created_at) < new Date(y.created_at) ? 1 : -1
            })
        );
    }
    return result;
}

export const getEntityFromStore = (state, id) => {
    if (state['entities'] && state['entities'].hasOwnProperty(id)) {
        return state['entities'][id];
    }
    return null;
}

