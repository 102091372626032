import {
    OPENAI_QUERY_SET,
    ENTITY_OPENAI_QUIERIES_RECEIVED
} from '../types';

const initialState = {
    entities: {}
};

const setQueries = (state, payload) => {
    console.log('setQueries', payload)
    if (!payload) return state;
    for (var id in payload) {
        var query = payload[id];
        state[query.id] = query;
        if (!state['entities'].hasOwnProperty(query.init_entity)) {
            state['entities'][query.init_entity] = []
        }
        state['entities'][query.init_entity].push(query.id)
    }
    return state;

};


const setQuery = (state, query) => {
    if (!query) return state;
    var newState = {
        ...state,
        [query.id]: query
    }
    if (!newState['entities'].hasOwnProperty(query.init_entity)) {
        newState['entities'][query.init_entity] = []
    }
    newState['entities'][query.init_entity].push(query.id)
    return newState;
};


const loading = function (state = initialState, action) {
    const { type, query, payload } = action;
    switch (type) {
        case OPENAI_QUERY_SET:
            return setQuery(state, query)
        case ENTITY_OPENAI_QUIERIES_RECEIVED:
            return setQueries(state, payload)
        default:
            return state;
    }
}

export default loading;
