import {SCOPE_GOOGLE, STATE_PREVIEW, TYPE_PLACE} from '../constants/constants';
import {useEffect, useState} from "react";


export const textTruncate = (text, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (text.length > length) {
    return text.substring(0, length - ending.length) + ending;
  } else {
    return text;
  }
};

export const match = (term, array, key) => {
  const reg = new RegExp(term.split('').join('.*'), 'i');
  return array.filter(item => item[key] && item[key].match(reg));
};

export const getUserName = user => {
  if (!user) {
    return '';
  }
  let name = (user.first_name && user.last_name) ? (user.first_name + ' ' + user.last_name) : user.username;
  return name;
};

export const getIsAuthor = (user, obj) => {
  if (user && obj && obj.author && (user.username === obj.author.username)) {
    return true;
  }
  return false;
};

export const getIsAdmin = (user) => {
  if (user && user.is_superuser) {
    return true;
  }
  return false;
};

export const canEdit = (user, obj) => {
  return getIsAuthor(user, obj) || getIsAdmin(user);
};

export const getIsPreview = (obj) => {
  if (obj.state === STATE_PREVIEW){
    return true;
  }
  return false;

};

export const getIsSupportedImageFormat = (img_type) => {
  const types = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg']

  if (types.every(type => type !== img_type)) {
    return false;
  }
  return true;
};

export const getPlaceFromGooglePlace = (googlePlace, preciseLat, preciseLng) => {
  var location = getLocationFromGooglePlace(googlePlace, preciseLat, preciseLng);
  var place = {};
  place.location = location;
  place.type = TYPE_PLACE;

  let name = googlePlace.name;
  if (!name && googlePlace.structured_formatting && googlePlace.structured_formatting.main_text) {
    name = googlePlace.structured_formatting.main_text;
  }
  place.name = name;

  let description = googlePlace.description ? googlePlace.description : '';
  if (googlePlace.international_phone_number) {
    description += '\nPhone: ' + googlePlace.international_phone_number;
  }
  if (googlePlace.rating) {
    description += '\nRating: ' + googlePlace.rating + ' of 5';
  }
  if (googlePlace.opening_hours && googlePlace.opening_hours.weekday_text) {
    let day = null;
    description += '\n';
    for (var j in googlePlace.opening_hours.weekday_text) {
      day = googlePlace.opening_hours.weekday_text[j];
      description += '\n' + day;
    }
  }

  place.description = description;
  place.scope = SCOPE_GOOGLE;
  place.state = STATE_PREVIEW;
  place.created_at = Date.now();


  if (googlePlace.photos && googlePlace.photos.length > 0) {
    let media = [];
    let media_photo = {}
    for (var i in googlePlace.photos) {
      var photo = googlePlace.photos[i];
      media_photo.thumb_url = photo.getUrl({'maxWidth': 500, 'maxHeight': 350});
      media_photo.image_url = photo.getUrl({'maxWidth': 800, 'maxHeight': 600});
      if (photo.html_attributions) {
        var template = document.createElement('template');
        template.innerHTML = photo.html_attributions;
        media_photo.owner_name = template.content.firstChild.innerText;
        media_photo.owner_url = template.content.firstChild.href;
      }
      media.push(media_photo);
    }
    place.media = media;
  }
  return place;

};


export const getLocationFromGooglePlace = (googlePlace, preciseLat, preciseLng) => {
  if (!googlePlace) {
    return null;
  }
  var location = {};
  if (googlePlace.geometry && googlePlace.geometry.location) {
    if (preciseLat && preciseLng && googlePlace.geometry.location_type !== "ROOFTOP") {
      location.lat = preciseLat;
      location.lng = preciseLng;
    } else {
      var lat = googlePlace.geometry.location.lat;
      var lng = googlePlace.geometry.location.lng;
      if (typeof lat === "function") {
        location.lat = lat();
      }
      if (typeof lng === "function") {
        location.lng = lng();
      }
    }
    var viewport = googlePlace.geometry.viewport;
    if (viewport) {
      var ne = googlePlace.geometry.viewport.getNorthEast;
      var sw = googlePlace.geometry.viewport.getSouthWest;
      if (typeof ne === "function") {
        ne = googlePlace.geometry.viewport.getNorthEast();
        location.ne_lat = ne.lat();
        location.ne_lng = ne.lng();
      } else {
        ne = googlePlace.geometry.viewport.northeast;
        location.ne_lat = ne.lat;
        location.ne_lng = ne.lng;
      }
      if (typeof sw === "function") {
        sw = googlePlace.geometry.viewport.getSouthWest();
        location.sw_lat = sw.lat();
        location.sw_lng = sw.lng();
      } else {
        sw = googlePlace.geometry.viewport.southwest;
        location.sw_lat = sw.lat;
        location.sw_lng = sw.lng;
      }
    }
  }
  location.address = googlePlace.formatted_address;
  location.vicinity = googlePlace.vicinity;
  location.types = googlePlace.types;
  location.phone = googlePlace.international_phone_number;
  location.place_id = googlePlace.place_id;
  location.website = googlePlace.website;
  location.rating = googlePlace.rating;
  location.price_level = googlePlace.price_level;

  if (googlePlace.address_components) {
    var address_comp = googlePlace.address_components;
    for (var i in address_comp) {
      var comp = address_comp[i];
      var type = comp.types[0];
      switch (type) {
        case 'street_number':
          location.number = comp.long_name;
          break;
        case 'route':
          location.street = comp.long_name;
          break;
        case 'locality':
          location.city = comp.long_name;
          break;
        case 'administrative_area_level_2':
          location.region = comp.long_name;
          break;
        case 'administrative_area_level_1':
          location.state = comp.long_name;
          break;
        case 'country':
          location.country = comp.long_name;
          break;
        case 'postal_code':
          location.postal_code = comp.long_name;
          break;
        default:
          break;
      }
    }
  }

  return location;
}


export const getLocationName = (location) => {
  if (!location) return null
  if (location.name) return location.name;
  if (location.address) return location.address;
  if (location.lat && location.lng) return location.lat + ", " + location.lng;
  return null;
}

export const setAuthHeaders = headersObject => {
  let headers = { ...headersObject };
  if (localStorage.jwt) {
    headers = {
      ...headers,
      Authorization: `Bearer ${localStorage.jwt}`
    };
  }
  return headers;
};

export const isBrowser = () => typeof window !== 'undefined';
export const getUser = () =>
    isBrowser() && window.localStorage.getItem('user')
        ? JSON.parse(window.localStorage.getItem('user'))
        : {};

export const setUser = user =>
    window.localStorage.setItem('user', JSON.stringify(user));
export const removeUser = () => window.localStorage.removeItem('user');
export const isLoggedIn = () => {
  const user = getUser();
  return !!user.username;
};

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)


  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [ref])

  return isIntersecting
}

export const getErrorMsg = (responseData) => {

  var result = '';
  if (responseData.errors) {
    for (const error in responseData.errors) {
      for (const msg in responseData.errors[error]) {
        result += responseData.errors[error][msg] + ' ';
      }
    }
    return result;
  }
  result = responseData.error ?
      responseData.error.message :
      (responseData.entity ? responseData.entity.detail : null);

  return result;
}



