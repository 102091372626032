export const MEDIA_GET_START = 'MEDIA_GET';
export const MEDIA_GET_FLICKR = 'MEDIA_GET_FLICKR';
export const MEDIA_GET_WIKI = 'MEDIA_GET_WIKI';
export const MEDIA_GET_WIKI_DETAILS= 'MEDIA_GET_WIKI_DETAILS';
export const MEDIA_GET_DONE = 'MEDIA_GET_DONE';
export const MEDIA_GET_GOOGLE = 'MEDIA_GET_GOOGLE';
export const MEDIA_ADDED = 'MEDIA_ADDED';
export const MEDIA_ADDED_EXTERNAL = 'MEDIA_ADDED_EXTERNAL';
export const MEDIA_DELETED = 'MEDIA_DELETED';
export const MEDIA_NAME_SAVED = 'MEDIA_NAME_SAVED';
export const MEDIA_OWNER_NAME_SAVED = 'MEDIA_OWNER_NAME_SAVED';
export const MEDIA_OWNER_URL_SAVED = 'MEDIA_OWNER_URL_SAVED';
export const MEDIA_RECEIVED = 'MEDIA_RECEIVED';
export const MEDIA_RECEIVED_LINKED_ENTITIES = 'MEDIA_RECEIVED_LINKED_ENTITIES';
export const MEDIA_GET_PIXABAY = 'MEDIA_GET_PIXABAY';