import {
    SEARCH_GET_GOOGLE_PLACES,
    SEARCH_GET_WIKI_PLACES,
    SEARCH_GET_WIKI_VOYAGE_PLACES,
    CLEAR_SEARCH
} from "../types";
import {Place} from "../../utils/Place"

const initialState = {
    googlePlaces: [],
    wikiPlaces: [],
    wikiVoyagePlaces: []
};

const getGooglePlaces = (state, place) => {
    return {
        ...state,
        googlePlaces: [place]
    };
};

const getWikiPlaces = (state, wikiPlaces, googlePlace) => {
    var places = [];
    for (var idx in wikiPlaces) {
        var wikiPlace = wikiPlaces[idx];
        var wawPlace = Place.getPlaceFromWikiPage(wikiPlace, googlePlace);
        places.push(wawPlace)
    }

    return {
        ...state,
        wikiPlaces: places
    };
};

const getWikiVoyagePlaces = (state, wikiPlaces, googlePlace) => {
    var places = wikiPlaces?.map(wiki => {
        return Place.getPlaceFromWikiPage(wiki, googlePlace);
    });
    return {
        ...state,
        wikiVoyagePlaces: places ? places : []
    };
};

const clearSearch = (state) => {
    return initialState;
};


const search = (state = initialState, action) => {
    const { type, payload, place} = action;

    switch (type) {
        case SEARCH_GET_GOOGLE_PLACES:
            return getGooglePlaces(state, place);
        case SEARCH_GET_WIKI_PLACES:
            return getWikiPlaces(state, payload, place);
        case SEARCH_GET_WIKI_VOYAGE_PLACES:
            return getWikiVoyagePlaces(state, payload, place);
        case CLEAR_SEARCH:
            return clearSearch(state);
        default:
            return state;
    }
};

export default search;