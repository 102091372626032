import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const Loading = (props) => {
    const {isLoading} = props;
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={isLoading}
                //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired
};

Loading.defaultProps = {
    isLoading: false
};

const mapStateToProps = state => ({
    isLoading: state.loadingState.loading
});
export default connect(mapStateToProps)(Loading);