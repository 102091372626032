import {TAGS_SET_LOADING, TAG_GET, TAG_GET_ALL, TAG_GET_POPULAR, TAG_ADD, TAG_DELETE} from '../types';

const initialState = {
  tags:null,
  tagsAll: null,
  tagsAllCount: 0,
  tagsPopular: [],
  tagsPopularCount: 0,
  tagsLoading:false
};

const setTagsLoading = (state, payload) => {
  return {
    ...state,
    tagsLoading:payload
  };
}

const addTag = (state, payload) => {
  if (state.tags != null) {
    state = [state.tags, payload]
  } else {
    state.tags = [payload]
  }
  return {
    ...state
  };
};

const deleteTag = (state, tagId) => {
  let items = state.tags.filter(item => {
    return item.id !== tagId
  })
  state.tags = items ? items : [];
  return {
    ...state
  };
};

const getEntityTags = (state, payload) => {
  return {
    ...state,
    tags:payload.tags,
    tagsCount: payload.tagsCount,
    tagsLoading:false
  };
};

const getAllTags = (state, payload) => {
  return {
    ...state,
    tagsAll:payload.tags,
    tagsAllCount: payload.tagsCount,
    tagsLoading:false
  };
};

const getPopularTags = (state, payload) => {
  return {
    ...state,
    tags:payload.tags,
    tagsCount: payload.tagsCount
  };
};


const tags = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TAG_GET:
      return getEntityTags(state, payload);
    case TAG_ADD:
      return addTag(state, payload);
    case TAG_DELETE:
      return deleteTag(state, payload);
    case TAG_GET_ALL:
      return getAllTags(state, payload);
    case TAG_GET_POPULAR:
      return getPopularTags(state, payload);
    case TAGS_SET_LOADING:
      return setTagsLoading(state, payload);
    default:
      return state;
  }
};
export default tags;


