import {setAlert} from "./alert";
import {REFERENCE_ADDED, REFERENCE_DELETED, REFERENCE_GET_ALL} from "../types/references";
import {getErrorMsg, setAuthHeadersAsJson} from "../../utils";

const WAW_API_ROOT = '/api';

export const getEntityReferences = (slug) => async dispatch => {
    try {
        const url = `/entities/${slug}/references`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: REFERENCE_GET_ALL, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const addReference = (slug, reference) => async dispatch => {
    try {
        const url = `/entities/${slug}/references`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(reference)
        });
        const responseData = await response.json();
        if (response.status === 201) {
            dispatch({type: REFERENCE_ADDED, payload: responseData, slug: slug});
        } else {
            const msg =  getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const deleteReference = (slug, referenceId) => async dispatch => {
    try {
        const url = `/entities/${slug}/references/${referenceId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeadersAsJson()
        });

        if (response.status === 204) {
            dispatch({type: REFERENCE_DELETED, slug: slug, referenceId:referenceId});
        } else {
            const responseData = await response.json();
            const msg =  getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }

    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};