import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import styles from './styles';
import {Link} from "react-router-dom";
import Social from '../../../../components/Social';

const pages = [
    {
        'name': 'Brainstorm',
        'url': '/brainstorm'
    },
    {
        'name': 'Notable Inspirations',
        'url': '/inspirations'
    },
    {
        'name': 'Explore',
        'url': '/explore?type=inspiration'
    }];


const AnonymousAppBar = (props) => {
    const {classes, isAuth, user, logout} = props;
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, toggleDrower] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <AppBar position="static" className={classes.appbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Box sx={{display: {xs: 'flex', md:'flex'}}} >
                        <Link to="/">
                            <img src="/logo-icon.svg" alt="Why And Where" className={classes.logoImg}/>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md:'flex'}}} >
                        <Link to="/">
                            <Typography variant="subtitle" className={classes.logoText} >
                                Why And Where
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to={page.url}>{page.name}</Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <Link to="/">
                            <img src="/logo-icon.svg" alt="Why And Where" className={classes.logoImg}/>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: '#263238', display: 'block',}}
                            >
                                <Link to={page.url}>
                                    {page.name}
                                </Link>
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{display: {xs: 'none', md: 'flex'}, marginRight: '10px'}}>
                        <Button
                            key='register'
                            sx={{my: 2, color: '#263238', display: 'block',}}
                        >
                            <Link to='/register'>
                                Register
                            </Link>
                        </Button>
                    </Box>
                    <Box sx={{display: {xs: 'none', md: 'flex'}, marginRight: '10px'}}>
                        <Button
                            key='register'
                            sx={{my: 2, color: '#263238', display: 'block',}}
                        >
                            <Link to='/login'>
                                Login
                            </Link>
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const mapStateToProps = state => ({
    isAuth: state.authState.isAuthenticated,
});

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(AnonymousAppBar));
