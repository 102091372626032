import {REFERENCE_ADDED, REFERENCE_DELETED, REFERENCE_GET_ALL} from "../types";

const initialState = {
    references: null,
    referenceLoading: true
};

const getReferences = (state, payload) => {
    return {
        references:payload.references,
        referenceCount:payload.referenceCount,
        referenceLoading: false
    };
};

const addReference = (state, payload) => {
    if (state.references != null) {
        state = [state.references, payload]
    } else {
        state.references = [payload]
    }
    return {
        ...state
    };
};

const deleteReference = (state, referenceId) => {
    let items = state.references.filter(item => {
        return item.id !== referenceId
    })
    state.references = items ? items : [];
    return {
        ...state
    };
};

const references = (state = initialState, action) => {
    const { type, payload, referenceId } = action;

    switch (type) {
        case REFERENCE_GET_ALL:
                return getReferences(state, payload);
        case REFERENCE_ADDED:
            return addReference(state, payload);
        case REFERENCE_DELETED:
            return deleteReference(state, referenceId);
        default:
            return state;
    }
};

export default references;