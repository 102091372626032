import {combineReducers} from 'redux';

import airport from './airport';
import ads from './ads';
import alert from './alert';
import auth from './auth';
import users from './users';
import entities from './entities';
import tags from './tags';
import profiles from './profiles';
import media from './media';
import references from './references';
import comments from './comments';
import calendar from './calendar';
import loading from './loading';
import notifications from './notifications';
import search from './search';
import openai from './openai';

export default combineReducers({
  adsState: ads,
  alertState: alert,
  authState: auth,
  userState: users,
  entityState:entities,
  tagState:tags,
  profileState: profiles,
  mediaState:media,
  referenceState:references,
  commentState:comments,
  calendarState:calendar,
  airportState:airport,
  loadingState: loading,
  notificationsState: notifications,
  searchState:search,
  openAIState:openai
});
