import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import LandingPage from './LandingPage';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const isDebug = process.env.REACT_APP_DEBUG;
console.log(process.env)
const component = isDebug === 'true' ? <App /> : <LandingPage />

ReactDOM.render(
  component,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();



