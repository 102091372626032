import {COMMENT_ADDED, COMMENT_DELETED, COMMENT_GET_ALL} from "../types";

const initialState = {
    comments: null,
    commentsCount: 0,
    commentsLoading: true
};

const getComments = (state, payload) => {
    return {
        comments:payload.comments,
        commentsCount:payload.commentCount,
        commentsLoading: false
    };
};

const addComment = (state, payload) => {
    if (state.comments != null) {
        state = [state.comments, payload]
    } else {
        state.comments = [payload]
    }
    return {
        ...state
    };
};

const deleteComment = (state, commentId) => {
    let items = state.comments.filter(item => {
        return item.id !== commentId
    })
    state.comments = items ? items : [];
    return {
        ...state
    };
};

const comments = (state = initialState, action) => {
    const { type, payload, commentId } = action;
    switch (type) {
        case COMMENT_GET_ALL:
            return getComments(state, payload);
        case COMMENT_ADDED:
            return addComment(state, payload);
        case COMMENT_DELETED:
            return deleteComment(state, commentId);
        default:
            return state;
    }
};
export default comments;