import {ENTRY_CREATED, ENTRY_DELETED, ENTRY_GET_ALL} from '../types';

const initialState = {
  selectedCalendarEntry:null,
  calendarEntries: [],
  calendarEntriesCount:0,
  loading:true
};
const getAllEntries = (state, payload) => {
  return {
    ...state,
    calendarEntries:payload.calentries,
    calendarEntriesCount: payload.calentriesCount,
    loading:false
  };
};
const getCreatedEntry = (state, payload) => {
  return {
    ...state,
    selectedCalendarEntry:payload.calentry,
    loading:false
  };
};

const getEntryDeleted = (state) => {
  return {
    ...state,
    loading:false
  };
};

const calendar = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENTRY_GET_ALL:
      return getAllEntries(state, payload);
    case ENTRY_CREATED:
      return getCreatedEntry(state, payload);
    case ENTRY_DELETED:
      return getEntryDeleted(state, payload);
    default:
      return state;
  }
};
export default calendar;
