const css = (theme) => ({
  root: {
    background: '#212121',
    color: "#fff",
    overflow: "hidden",
    padding: "2em 0 ",
    bottom:'0',
    width:'100%',
    maxWidth:'100%'
  },
  title: {
    textTransform: 'uppercase',
    fontSize:'2em',
    fontWeight:'500',
    padding:'10px'
  },
  copyright: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  row:{
    padding: '5px',
    textAlign:'left'
  },
  link: {
    fontSize: "1.5em",
    color: "#fff",
    textDecoration:'none',
    "& a": {
      color: "#fff",
      textDecoration:'none'
    },
    "&:hover": {
      color: "#fff",
      textDecoration:'none'
    },
  },
  social: {
    fontSize: "4em",
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
  snsIcon: {
    color:'#fff',
    fontSize:'30px',
    textAlign:'left',
    "&:hover": {
      color: '#fff',
    },
  },
  copylight: {
    color: "#fff",
    fontSize: "1em",
    "&:hover": {
      color: "#fff",
    },
  },
  designed:{
    color: "#fff",
    textDecoration:'none',
    textTransform: 'uppercase',
    padding:'0 5px 0 5px',
    "& a": {
      color: "#fff",
      textDecoration:'none'
    },
    "&:hover": {
      color: "#fff",
      textDecoration:'none'
    },
  },
  input: {
    padding:'10px',
    minWidth:'200px'
  },
  label: {
    fontSize:'2em',
    marginRight:'5px'
  }
});
export default css;
