import React from "react";

const Title = () => {

  return (
    <div className="titleContent">
      AI Driven<br /> Travel Exploration
      <p>AI Assistant can help you with travel planning by analyzing vast amounts of data to provide personalized recommendations for destinations, accommodations, and activities.</p>
    </div>
  );
};
export default Title;
