import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomizedSnackbar from '../components/Alert/CustomizedSnackbar';

const Logo = ({alert}) => {

  return (
    <>
    <div className="landingLogo">
      <Box sx={{ display: { xs: "flex", md: "flex" }, alignItems: "center" }}>
        <img src="/logo-icon.svg" alt="Why And Where" className="landingLogoImg" />
        <Typography variant="subtitle" className="landingLogoText">
          Why And Where
        </Typography>
        <img src="/beta.svg" alt="beta" className="landingBetaImg" />
      </Box>
    </div>
    <CustomizedSnackbar
            key={alert.msg}
            isOpen={alert.isOpen}
            vertical="top"
            horizontal="center"
            variant={alert.type}
            message={alert.msg}
        />
    </>
  );
};
export default Logo;



