import {setAlert} from './alert';
import {NOTIFICATIONS_GET_UNREAD} from '../types';

import {setAuthHeadersAsJson} from '../../utils';
import {removeLoading, setLoading} from "./loading";

const limit = (count, p) => ``;
const WAW_API_ROOT = '/api';


export const getUnreadNotifications = () => async dispatch => {
    dispatch(setLoading(5000))
    try {
        const url = `/notifications/unread/?format=json`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson()
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: NOTIFICATIONS_GET_UNREAD, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
    dispatch(removeLoading(5000))
};