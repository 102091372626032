import {
    AUTH_ERROR,
    DONE_REFRESHING_TOKEN,
    LOADING_TOKENS,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REFRESHING_TOKEN,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    RESET_CONFIRM,
    UPDATE_TOKENS,
    USER_LOADED
} from '../types';
import {getUser} from "../../utils";
import jwtDecode from "jwt-decode";
import moment from "moment";

const getIsAuthenticated = () => {
    var accessToken = localStorage.getItem('jwt_access_token')
    if (!accessToken || accessToken === 'undefined') return false;
    const decoded = jwtDecode(accessToken);
    if (decoded.exp && decoded.exp - moment().unix() <= 0) {
        return false;
    }
    return true;
}

const initialState = {
    loading: false,
    user: getUser(),
    isResetComplete: false,
    accessToken: localStorage.getItem('jwt_access_token'),
    refreshToken: localStorage.getItem('jwt_refresh_token'),
    isAuthenticated: getIsAuthenticated(),
    loadingToken: false,
    promise: null
};

const auth = (state = initialState, action) => {
    const {type, payload, access_token, refresh_token, promise} = action;
    switch (type) {
        case LOADING_TOKENS:
            return {
                ...state,
                loadingToken: true
            };
        case REFRESHING_TOKEN:
            return {
                ...state,
                promise: promise
            };
        case DONE_REFRESHING_TOKEN:
            return {
                ...state,
                promise: null
            };
        case UPDATE_TOKENS:
            localStorage.setItem('jwt_access_token', access_token);
            localStorage.setItem('jwt_refresh_token', refresh_token);
            var newstate = {
                ...state,
                loadingToken: false,
                isAuthenticated:true,
            };
            return newstate;
        case USER_LOADED:
            return {
                ...state,
                user: payload,
                isAuthenticated: true,
                loading: false,
            };
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('jwt_access_token', access_token);
            localStorage.setItem('jwt_refresh_token', refresh_token);
            var context  = {
                ...state,
                isAuthenticated: true,
                loading: false,
            };
            if (payload) {
                context['user'] = payload
            }
            return context
        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            localStorage.removeItem('jwt_access_token');
            localStorage.removeItem('jwt_refresh_token');
            return initialState;
        case RESET_CONFIRM:
            return {...state, isResetComplete: true, loading: false};
        default:
            return state;
    }
};

export default auth;
