const css = (theme) => ({
    txt_root: {
        display:'inline-block'
    },
    icon_root: {
        position:'absolute',
        top:'8px',
        zIndex:99,
        display:'block'
    },
    icon: {
        fontSize: "40px",
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1
    },
    upload: {
        display: "none"
    },
    label: {
        marginBottom: 0

    }
});
export default css;