import * as React from 'react';
import {useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {getUnreadNotifications, logout} from "../../../../store/actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import styles from './styles';
import {Link, Redirect} from "react-router-dom";
import {UserDrawer} from '../../components';
import {Alert, Fade, Popper, Stack} from "@mui/material";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const pages = [
    {
        'name': 'Brainstorm',
        'url': '/brainstorm'
    },
    {
        'name': 'Notable Inspirations',
        'url': '/inspirations'
    },
    {
        'name': 'Explore',
        'url': '/explore?type=inspiration'
    }];

const AuthenticatedAppBar = (props) => {
    const {classes, user, logout, notifications, getUnreadNotifications} = props;

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, toggleDrower] = React.useState(false);


    const [showUnreadOnly, setShowUnreadOnly] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [anchor, setAnchor] = React.useState(null);

    const toggleNotificationCenter = (event) => {
        setAnchor(event.currentTarget);
        setIsOpen(!isOpen);
    };
    const toggleFilter = (e) => {
        setShowUnreadOnly(!showUnreadOnly);
    };
    useEffect(() => {
        if (!notifications ) {
            getUnreadNotifications();
        }
    }, [notifications, getUnreadNotifications]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        window.location.reload()
    };

    const toggleDrawer = () => {
        toggleDrower(!drawerOpen);
    };


    const clearAll = () => {
    }
    const markUnreadAll = () => {
    }
    const markAsRead = (id) => {
    }


    /*const {
        notifications,
        clear,
        markAllAsRead,
        markAsRead,
        unreadCount
    } = useNotificationCenter();*/


    return (
        <AppBar position="static" className={classes.appbar} elevation={0}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Box sx={{display: {xs: 'flex', md:'flex'}}} >
                        <Link to="/">
                            <img src="/logo-icon.svg" alt="Why And Where" className={classes.logoImg}/>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md:'flex'}}} >
                        <Link to="/">
                            <Typography variant="subtitle" className={classes.logoText} >
                                Why And Where
                            </Typography>
                        </Link>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'}
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to={page.url}>{page.name}</Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (

                            <Button
                                key={page.name}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: '#263238', display: 'block',}}
                            >
                                <Link to={page.url}>
                                    {page.name}
                                </Link>
                            </Button>
                        ))}
                    </Box>


                    <Box sx={{display: {xs: 'flex', md: 'flex'}, marginRight: '10px'}} className={classes.more}>
                        <MoreHorizIcon key='dashboard' fontSize="large" onClick={toggleDrawer} />
                    </Box>

                    <Box sx={{display: {xs: 'none', md: 'flex'}, marginRight: '10px'}}>
                        <IconButton
                            size="large"
                            aria-label="new notifications"
                            color="inherit"
                            onClick={toggleNotificationCenter}
                        >
                            <Badge badgeContent={notifications ? notifications.length : null} color="error">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                    </Box>
                    <Box sx={{flexGrow: 0}}>

                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            {user && user.image ?
                                <Avatar alt={user.first_name + ' ' + user.last_name} src={user.image}/>
                                :
                                <AccountCircle/>
                            }
                        </IconButton>
                        <Menu
                            sx={{mt: '45px', minWidth: '80px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key='profile' onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">
                                        <Link to={'/profile/@' + user.username}>Profile</Link>
                                    </Typography>
                            </MenuItem>

                            <MenuItem key='logout' onClick={logout}>
                                <Typography >Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            <UserDrawer toggleDrawer={toggleDrawer} open={drawerOpen}/>

            <Popper open={isOpen} anchorEl={anchorEl} transition >
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350} style={{position: 'fixed', top: '60px', right:0}}>
                        <Box>
                            <Box
                                sx={{
                                    background: "#fff",
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                            </Box>
                            <Stack
                                sx={{
                                    height: "400px",
                                    width: "min(60ch, 100ch)",
                                    padding: "12px",
                                    background: "#fff",
                                    borderRadius: "8px",
                                    overflowY: "auto"
                                }}
                                spacing={2}
                            >
                                {(!notifications.length ||
                                    (notifications && notifications.length === 0 )) && (
                                    <h4>
                                        No messages
                                    </h4>
                                )}
                                {(showUnreadOnly
                                        ? notifications.filter((v) => v.unread)
                                        : notifications
                                ).map((notification) => {
                                    return (
                                        <Alert
                                            severity={(notification.level) || "info"}
                                            action={
                                                !notification.unread ? (
                                                    <CheckIcon/>
                                                ) : (
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="span"
                                                        onClick={() => markAsRead(notification.id)}
                                                    >
                                                        <MarkChatReadIcon/>
                                                    </IconButton>
                                                )
                                            }
                                        >
                                            {notification.description}
                                        </Alert>
                                    );
                                })}
                            </Stack>
                            <Box
                                sx={{
                                    background: "#fff",
                                    padding: "8px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Button variant="outlined" onClick={clearAll}
                                >
                                    Clear All
                                </Button>

                                <Button variant="outlined" onClick={markUnreadAll}>
                                    Mark as read
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </AppBar>
    );
};

function mapStateToProps({entityState, authState, notificationsState, props}) {
    return {
        isAuthenticated: authState.isAuthenticated,
        createdEntity: entityState.selectedEntity,
        notifications: notificationsState.unreadNotifications,
        user: authState.user
    }
};

const mapDispatchToProps = {
    getUnreadNotifications,
    logout
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AuthenticatedAppBar));
