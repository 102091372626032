import {setAlert} from "./alert";
import {COMMENT_ADDED, COMMENT_DELETED, COMMENT_GET_ALL} from "../types/comments";
import {getErrorMsg, setAuthHeaders, setAuthHeadersAsJson} from "../../utils";

const WAW_API_ROOT = '/api';
export const getEntityComments = (slug) => async dispatch => {
    try {
        const url = `/entities/${slug}/comments`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'GET',
            headers: setAuthHeadersAsJson(),
        });
        const responseData = await response.json();
        if (response.ok) {
            dispatch({type: COMMENT_GET_ALL, payload: responseData});
        }
        if (responseData.error) {
            dispatch(setAlert(responseData.error.message, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const addComment = (slug, comment) => async dispatch => {
    try {
        const url = `/entities/${slug}/comments`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'POST',
            headers: setAuthHeadersAsJson(),
            body: JSON.stringify(comment)
        });
        const responseData = await response.json();
        if (response.status === 201) {
            dispatch({type: COMMENT_ADDED, payload: responseData, slug: slug});
        } else {
            const msg =  getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};

export const deleteComment = (slug, commentId) => async dispatch => {
    try {
        const url = `/entities/${slug}/comments/${commentId}`;
        const response = await fetch(`${WAW_API_ROOT}${url}`, {
            method: 'DELETE',
            headers: setAuthHeaders(),
        });
        if (response.status === 204) {
            dispatch({type: COMMENT_DELETED, slug: slug, commentId:commentId});
        } else {
            const responseData = await response.json();
            const msg =  getErrorMsg(responseData)
            dispatch(setAlert(msg, 'error', 5000));
        }
    } catch (error) {
        dispatch(setAlert(error.message, 'error', 5000));
    }
};