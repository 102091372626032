import React from 'react';
import {connect} from 'react-redux';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {getIsSupportedImageFormat} from "../../../utils/utils";
import Typography from '@mui/material/Typography';

import {addMedia} from '../../../store/actions';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core';
import styles from './styles.js';
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1000,
            1000,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });


const ButtonUpload = props => {
    const { classes } = props;
    const save = async(ev) => {
        ev.preventDefault();
        let errs = []
        let file = ev.target.files[0];

        const formData = new FormData()
        if (!getIsSupportedImageFormat(file.type)) {
            errs.push(`'${file.type}' is not a supported format`);
            console.log(errs)
        } else {
            const image = await resizeFile(file);
            formData.set('image',  image);
            props.addMedia(props.slug, props.id, formData);
        }
    };

    return (
        <React.Fragment>
            {props.isIcon ?
                <div className={classes.icon}>
                    <label htmlFor={props.id} >
                        <CloudUploadIcon className={classes.icon}/>
                    </label>
                    <input id={props.id} type='file' onChange={save} className={classes.upload}/>
                </div>
                :
                <div className={classes.txt}>
                    <label htmlFor={props.id} className={classes.label} >
                        <Typography variant="inherit">Upload Picture</Typography>
                    </label>
                    <input id={props.id} type='file' onChange={save} className={classes.upload}/>
                </div>
            }
        </React.Fragment>
    );
};

ButtonUpload.propTypes = {
    id: PropTypes.number.isRequired,
    slug:PropTypes.string.isRequired,
    isIcon:PropTypes.bool.isRequired
};


const mapDispatchToProps = {
    addMedia
};

export default withStyles(styles)(connect(() => ({}), mapDispatchToProps)(ButtonUpload));
