

export const getFormattedAddress = (place) => {
    if (!place) return '';
    var address = place.location?.address;
    if (!address || address.trim() === '') {
        address = (place.location?.lat + ', ' + place.location?.lng);
    }
    return address;
}

function Place(id, name, description, lat, lng, ne_lat, ne_lng, sw_lat, sw_lng, url, scope, state) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.geo_lat = lat;
    this.geo_lng = lng;
    this.geo_ne_lat = ne_lat;
    this.geo_ne_lng = ne_lng;
    this.geo_sw_lat = sw_lat;
    this.geo_sw_lng = sw_lng;
    this.url = url;
    this.scope = scope;
    this.state = state;
    this.type = "place";
}

Place.prototype.addPictures = function (pictures, total, scope) {
    if (!this[scope] || !pictures) {
        this[scope] = {};
    }
    if (!this[scope].count) {
        this[scope].count = 0;
    }
    this[scope].count += pictures.length;
    this[scope].pictures = pictures;
    this[scope].total = total;
}

Place.prototype.addPicture = function (picture, scope) {
    if (!picture) {
        return;
    }
    if (!this[scope]) {
        this[scope] = {};
    }
    this[scope].count = (!this[scope].count) ? 1 : this[scope].count++;
    this[scope].total = (!this[scope].total) ? 1 : this[scope].total++;

    if (!this[scope].pictures || this[scope].pictures === 0) {
        this[scope].pictures = [];
        this[scope].pictures.push(picture);
    } else {
        this[scope].pictures.push(picture);
    }
}


Place.prototype.getFormattedAddress = function () {
    var address = this.geo_address_full;
    if (!address || address.trim() === '') {
        address = (this.geo_lat + ', ' + this.geo_lng);
    }
    return address;
}

Place.prototype.isEditable = function () {
    return false;
}

Place.getPlaceFromGoogle = function (googlePlace, name, scope, preciseLat, preciseLng) {
    if (!googlePlace) {
        return null;
    }
    var lat, lng = null;
    var ne_lat, ne_lng, sw_lat, sw_lng = null;
    if (googlePlace.geometry && googlePlace.geometry.location) {
        if (preciseLat && preciseLng && googlePlace.geometry.location_type !== "ROOFTOP") {
            lat = preciseLat;
            lng = preciseLng;
        } else {
            lat = googlePlace.geometry.location.lat;
            lng = googlePlace.geometry.location.lng;
            if (typeof lat === "function") {
                lat = lat();
            }
            if (typeof lng === "function") {
                lng = lng();
            }
        }
        var viewport = googlePlace.geometry.viewport;
        if (viewport) {
            var ne = googlePlace.geometry.viewport.getNorthEast;
            var sw = googlePlace.geometry.viewport.getSouthWest;
            if (typeof ne === "function") {
                ne = googlePlace.geometry.viewport.getNorthEast();
                ne_lat = ne.lat();
                ne_lng = ne.lng();
            } else {
                ne = googlePlace.geometry.viewport.northeast;
                ne_lat = ne.lat;
                ne_lng = ne.lng;
            }
            if (typeof sw === "function") {
                sw = googlePlace.geometry.viewport.getSouthWest();
                sw_lat = sw.lat();
                sw_lng = sw.lng();
            } else {
                sw = googlePlace.geometry.viewport.southwest;
                sw_lat = sw.lat;
                sw_lng = sw.lng;
            }
        }
    } else {
        return null;
    }

    if (!name) {
        name = googlePlace.name;
        if (!name && googlePlace.structured_formatting && googlePlace.structured_formatting.main_text) {
            name = googlePlace.structured_formatting.main_text;
        }
    }
    let description = googlePlace.description ? googlePlace.description : '';
    if (googlePlace.international_phone_number) {
        description += '\nPhone: ' + googlePlace.international_phone_number;
    }
    if (googlePlace.rating) {
        description += '\nRating: ' + googlePlace.rating + ' of 5';
    }
    if (googlePlace.opening_hours && googlePlace.opening_hours.weekday_text) {
        let day = null;
        description += '\n';
        for (var j in googlePlace.opening_hours.weekday_text) {
            day = googlePlace.opening_hours.weekday_text[j];
            description += '\n' + day;
        }
    }
    var place = new Place(Date.now(), name, description,
        lat, lng,
        ne_lat, ne_lng, sw_lat, sw_lng,
        googlePlace.url,
        "google",
        "preview");
    place.geo_address_full = googlePlace.formatted_address;
    place.geo_formatted_address = googlePlace.formatted_address;
    place.geo_vicinity = googlePlace.vicinity;
    place.geo_types = googlePlace.types;
    place.geo_phone = googlePlace.international_phone_number;
    place.geo_place_id = googlePlace.place_id;
    place.geo_website = googlePlace.website;
    place.geo_rating = googlePlace.rating;
    place.geo_price_level = googlePlace.price_level;
    place.reference = googlePlace.website ? googlePlace.website : googlePlace.url;

    if (googlePlace.address_components) {
        var address_comp = googlePlace.address_components;
        for (var i in address_comp) {
            var comp = address_comp[i];
            var type = comp.types[0];
            switch (type) {
                case 'street_number':
                    place['geo_number'] = comp.long_name;
                    break;
                case 'route':
                    place['geo_street'] = comp.long_name;
                    break;
                case 'locality':
                    place['geo_city'] = comp.long_name;
                    break;
                case 'administrative_area_level_2':
                    place['geo_region'] = comp.long_name;
                    break;
                case 'administrative_area_level_1':
                    place['geo_state'] = comp.long_name;
                    break;
                case 'country':
                    place['geo_country'] = comp.long_name;
                    break;
                case 'postal_code':
                    place['geo_postal_code'] = comp.long_name;
                    break;
                default:
                    break;
            }
        }
    }
    if (googlePlace.photos && googlePlace.photos.length > 0) {
        var pictures = [];
        var thumb_url, photo_url, photo_owner_name, photo_owner_url = null;
        for (var item in googlePlace.photos) {
            var photo = googlePlace.photos[item];
            thumb_url = photo.getUrl({'maxWidth': 500, 'maxHeight': 350});
            photo_url = photo.getUrl({'maxWidth': 800, 'maxHeight': 600});
            if (photo.html_attributions) {
                var template = document.createElement('template');
                template.innerHTML = photo.html_attributions;
                photo_owner_name = template.content.firstChild.innerText;
                photo_owner_url = template.content.firstChild.href;
            }
            var picture = new Picture(thumb_url, photo_url, photo_owner_name,
                photo_owner_url, "google");
            pictures.push(picture);
        }
        var total = googlePlace.photos.length;
        place.addPictures(pictures, total, "google");
    } else if (scope === "google") {
        Place.addGooglePictures(place, googlePlace);
    }
    return place;
}


Place.addGooglePictures = function (place, googlePlace) {
    let scope = "google";
    if (googlePlace.photos && googlePlace.photos.length > 0) {
        let pictures = [];
        let thumb_url, photo_url, photo_owner_name, photo_owner_url = null;
        for (var i in googlePlace.photos) {
            var photo = googlePlace.photos[i];
            thumb_url = photo.getUrl({'maxWidth': 500, 'maxHeight': 350});
            photo_url = photo.getUrl({'maxWidth': 800, 'maxHeight': 600});
            if (photo.html_attributions) {
                var template = document.createElement('template');
                template.innerHTML = photo.html_attributions;
                photo_owner_name = template.content.firstChild.innerText;
                photo_owner_url = template.content.firstChild.href;
            }
            var picture = new Picture(thumb_url, photo_url, photo_owner_name,
                photo_owner_url, "google");
            pictures.push(picture);
        }
        let total = googlePlace.photos.length;
        if (!place[scope]) {
            place[scope] = {};
        }
        if (!place[scope].count) {
            place[scope].count = 0;
        }
        place[scope].count += pictures.length;
        place[scope].pictures = pictures;
        place[scope].total = total;
    }
    return place;
}


Place.getPlaceFromWikiPage = function (wikiPage, googlePlace) {
    if (!wikiPage) {
        return null;
    }
    let lat, lng = null;
    if (wikiPage.coordinates) {
        lat = wikiPage.coordinates[0].lat;
        lng = wikiPage.coordinates[0].lon;
    }
    let name = wikiPage.title;
    let description = wikiPage.extract;
    let url = wikiPage.canonicalurl;
    let place = null;
    if (googlePlace) {
        if (googlePlace.geometry) {
            place = Place.getPlaceFromGoogle(googlePlace, name, "wikipedia", lat, lng);
        } else {
            place = googlePlace;
        }
        place.id = Date.now();
        place.name = name;
        place.description = description;
        place.url = url;
        place.scope = 'wikipedia';
        place.geo_lat = lat;
        place.geo_lng = lng;
    } else {
        place = new Place(Date.now(), name, description, lat, lng, url,
            "wikipedia", "preview");
    }
    place.source = wikiPage.pageid;

    if (wikiPage.thumbnail) {
        var photoUrl = wikiPage.thumbnail.source;
        var picture = new Picture(photoUrl, photoUrl, wikiPage.pageimage,
            photoUrl, "wikipedia");
        place.addPictures([picture], 1, "wikipedia");
    }
    return place;
}

Place.getPlaceFromWAW = function (wawPlace) {
    let place = new Place();
    //place = $.extend(true, place, wawPlace);

    if (place.geo_lat && (typeof place.geo_lat == "string")) {
        place.geo_lat = parseFloat(place.geo_lat);
        place.geo_lng = parseFloat(place.geo_lng);
    }
    return place;
}

function Picture(thumb_url, photo_url, photo_owner_name, photo_owner_url, scope) {
    this.thumb_url = thumb_url;
    this.url = photo_url;
    this.owner_name = photo_owner_name;
    this.owner_url = photo_owner_url;
    this.scope = scope;
}

export {Place, Picture}
