import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  READ_TOKENS,
  REGISTER_FAIL,
  RESET_CONFIRM,
  USER_LOADED
} from '../types';
import { setAlert } from './alert';
import { isLoggedIn, removeUser, setAuthHeadersAsJson, setAuthHeadersAsJsonWithCsrf, setUser } from '../../utils';

const WAW_API_ROOT = '/auth';

const displayErrors = (responseData, dispatch) => {
  for (const error in responseData.errors) {
    for (const msg in responseData.errors[error]) {
      dispatch(setAlert("Error:  " + responseData.errors[error][msg], 'error', 5000));
    }
  }
}

const getName = (data) => {
  var name = '';
  if (data.name) {
    name = data.name;
  } else if (data.first_name && data.last_name) {
    name = data.first_name + ' ' + data.last_name;
  } else {
    name = data.username;
  }
  return name;
}

export const uploadImage = (id, image) => async dispatch => {
  try {
    const data = new FormData();
    data.append('file', image);
    const url = '/users/photo/' + id;
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      body: data
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch(setAlert('Image Uploaded', 'success', 5000));
    }
    if (responseData.errors) {
      displayErrors(responseData, dispatch)
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
  }
};

// Login user
export const login = (email, password, username, onSuccess) => async dispatch => {
  try {
    const url = '/dj-rest-auth/login/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify({ email, password, username })
    });
    const responseData = await response.json();
    if (response.ok) {
      const { user, access_token, refresh_token } = responseData;
      user && setUser(user);
      dispatch({ type: LOGIN_SUCCESS, payload: user, access_token: access_token, refresh_token: refresh_token });
      var name = getName(user);
      dispatch(setAlert(`Welcome ${name}`, 'success', 5000));
      if (onSuccess) onSuccess();

    }
    if (responseData.errors) {
      dispatch({ type: LOGIN_FAIL });
      displayErrors(responseData, dispatch);
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert(error.message, 'error', 5000));
  }
};

export const validateToken = (access_token) => async dispatch => {
  try {
    const url = '/dj-rest-auth/token/validate';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify({ 'token': access_token })
    });

  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert(error.message, 'error', 5000));
  }
};
export const setLoadingTokens = () => async dispatch => {
  return function (dispatch, getState) {
    if (!getState().authState.tokenPromise) {
      // You don’t have to return Promises, but it’s a handy convention
      // so the caller can always call .then() on async dispatch result.
      return Promise.resolve();
    }

    //Do this action before starting the next one below
    //dispatch(simple_action());

    // We can dispatch both plain object actions and other thunks,
    // which lets us compose the asynchronous actions in a single flow.
    var state = getState()
    state.authState.tokenPromise = dispatch(refreshJWTToken1(state));
    return getState().authState.tokenPromise;
    /*.then(() =>
    Promise.all([
      dispatch(makeASandwichWithSecretSauce('Me')),
      dispatch(makeASandwichWithSecretSauce('My wife'))
    ])
).then(() =>
    dispatch(makeASandwichWithSecretSauce('Our kids'))
).then(() =>
    dispatch(getState().myMoney > 42 ?
        withdrawMoney(42) :
        apologize('Me', 'The Sandwich Shop')
    )
);*/
  };
}

export const refreshJWTToken1 = (state) => async dispatch => {
  try {
    const refresh_token = localStorage.getItem('jwt_refresh_token');
    const url = '/dj-rest-auth/token/refresh/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify({ 'token': refresh_token })
    });
    const responseData = await response.json();
    if (response.ok) {
      const { access, refresh } = responseData;
      localStorage.setItem('jwt_access_token', access);
      localStorage.setItem('jwt_refresh_token', refresh);
    }
    state.authState.tokenPromise = null;
    //return state;
  } catch (error) {
    console.log(error);
  }
};


export const refreshJWTToken = (refresh_token) => async dispatch => {
  //try {

  //const refresh_token = localStorage.getItem('jwt_refresh_token');
  const url = '/dj-rest-auth/token/refresh/';
  const response = await fetch(`${WAW_API_ROOT}${url}`, {
    method: 'POST',
    headers: setAuthHeadersAsJson(),
    body: JSON.stringify({ 'token': refresh_token })
  });

  const responseData = await response.json();
  return response;
};

export const reset = (email) => async dispatch => {
  try {
    const url = '/dj-rest-auth/password/reset/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJsonWithCsrf(),
      body: JSON.stringify(email)
    });
    const responseData = await response.json();
    if (response.ok) {
      var msg = responseData.detail;
      dispatch(setAlert(`${msg}`, 'success', 5000));
    }
    if (responseData.errors) {
      displayErrors(responseData, dispatch);
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert(error.message, 'error', 5000));
  }
};


export const resetConfirm = (user_key, token, password1, password2) => async dispatch => {
  try {
    const url = '/dj-rest-auth/password/reset/confirm/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJsonWithCsrf(),
      body: JSON.stringify({
        'uid': user_key,
        'token': token,
        'new_password1': password1,
        'new_password2': password2,
      })
    });
    const responseData = await response.json();
    if (response.ok) {
      var msg = responseData.detail;
      dispatch(setAlert(`${msg}`, 'success', 5000));
      dispatch({ type: RESET_CONFIRM });
    }
    if (responseData.errors) {
      displayErrors(responseData, dispatch);
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
  }
};

export const facebookLogin = e => async dispatch => {
  try {
    const username = e.name.replace(/\s/g, '') + '-facebook';
    var data = {
      ...e,
      username: username,
      access_token: e.accessToken
    }
    if (e.picture && e.picture.data && !e.picture.data.is_silhouette) {
      data = {
        ...data,
        image: e.picture.data.url
      }
    }
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

    const url = '/dj-rest-auth/facebook/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, options);
    const responseData = await response.json();

    if (response.ok) {
      const { user } = responseData;
      user && setUser(user);
      dispatch({ type: LOGIN_SUCCESS, payload: responseData });
      var name = getName(user);
      dispatch(setAlert(`Welcome ${name}`, 'success', 5000));
    }
    if (responseData.errors) {
      dispatch({ type: LOGIN_FAIL });
      displayErrors(responseData, dispatch);
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert(error.message, 'error', 5000));
  }
};

export const fetchUserInfo = (token) => async dispatch => {
  const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
  });

  var result = await response.json();
  result.access_token = token;
  return result;
}

export const googleLogin1 = () => async dispatch => {
  try {
    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirect_uri = "http://localhost:8000/api/dj-rest-auth/login/";
    const tokens = await fetch(`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect_uri}&prompt=consent&response_type=code&client_id=${client_id}&access_type=offline`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log(tokens)

  } catch (error) {
    console.log(error)
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert("Problem with Google Authentication. Try again later.", 'error', 5000));
  }
};



export const googleLogin = info => async dispatch => {
  try {
    console.log(info)
    if (!info) throw new Error("Unable login with Google info.");
    const code = info.code;
    const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        //Authorization: `Bearer ${accees_token}`,
        'Content-Type': 'application/json'
      },
    });

    var user = await res.json();
    user.access_token = code;

    console.log('googleLogin', user);

    const options = {
      method: 'POST',
      headers: setAuthHeadersAsJson(),
      body: JSON.stringify({ 'email': user.email, 'profile': user, 'code': code })
    };
    const url = '/dj-rest-auth/google/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, options);
    const responseData = await response.json();

    if (response.ok) {
      const { user, access_token, refresh_token } = responseData;
      user && setUser(user);
      dispatch({ type: LOGIN_SUCCESS, payload: user, access_token: access_token, refresh_token: refresh_token });
      var name = getName(user);
      dispatch(setAlert(`Welcome ${name}`, 'success', 5000));
    }
    if (responseData.errors) {
      dispatch({ type: LOGIN_FAIL });
      displayErrors(responseData, dispatch)
    }
  } catch (error) {
    console.log(error)
    dispatch({ type: LOGIN_FAIL });
    dispatch(setAlert("Problem with Google Authentication. Try again later.", 'error', 5000));
  }
};

// Register user
export const register = ({ first_name,
  last_name,
  username,
  email,
  password1,
  password2
}) => async dispatch => {
  try {
    const url = '/dj-rest-auth/registration/';
    const body = { first_name, last_name, username, email, password1, password2 };
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      //const { user } = responseData;
      //user && setUser(user);
      //if (image) dispatch(uploadImage(user._id, image)); // Upload image
      //dispatch({ type: REGISTER_SUCCESS, payload: responseData });
      //dispatch(setAlert('Register Success', 'success', 5000));
      dispatch(setAlert(responseData['detail'], 'success', 5000));
    }
    if (responseData.errors) {
      dispatch({ type: REGISTER_FAIL });
      displayErrors(responseData, dispatch);
    }
  } catch (error) {
    dispatch({ type: REGISTER_FAIL });
    dispatch(setAlert(error.message, 'error', 5000));
  }
};

export const readTokens = () => async dispatch => {
  dispatch({ type: READ_TOKENS });
}

export const loadUser = () => async dispatch => {
  if (!isLoggedIn()) return;
  try {
    const url = '/users/me/';
    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'GET',
      headers: setAuthHeadersAsJson()
    });
    const responseData = await response.json();
    if (response.ok) {
      const { user } = responseData;
      user && setUser(user);
      dispatch({ type: USER_LOADED, payload: user });
    }
    if (!response.ok) dispatch({ type: AUTH_ERROR });
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Logout
export const logout = () => async dispatch => {
  try {
    const url = '/dj-rest-auth/logout/';

    const response = await fetch(`${WAW_API_ROOT}${url}`, {
      method: 'POST',
      headers: setAuthHeadersAsJson()
    });
    const responseData = await response.json();
    if (response.ok) {
      removeUser();
      dispatch({ type: LOGOUT });
      dispatch(setAlert('Successfully Logged Out', 'success', 5000));
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error.message, 'error', 5000));
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
  }
};
