import {
    AIRPORT_ENTITY_ADDED,
    AIRPORT_ENTITY_DELETED,
    AIRPORT_ENTITY_GET_ALL,
    AIRPORT_GET_TO,
    AIRPORT_GET_FROM,
    AIRPORT_PROFILE_ADDED,
    AIRPORT_PROFILE_DELETED,
    AIRPORT_PROFILE_GET_ALL
} from '../types';

const initialState = {
  toAirports: null,
  fromFirports: null,
  loading:true
};
const getProfileAirports = (state, payload) => {
  return {
    ...state,
    selectedProfileAirports:payload.airports,
    selectedProfileAirportsCount: payload.airportsCount,
    loading:false
  };
};

const addProfileAirport = (state, payload) => {
  return {
    ...state,
    selectedProfileAirports:payload.airports,
    selectedProfileAirportsCount: payload.airportsCount,
    loading:false
  };
};

const deleteProfileAirport = (state, payload) => {
  return {
    ...state,
    selectedProfileAirports:payload.airports,
    selectedProfileAirportsCount: payload.airportsCount,
    loading:false
  };
};

const getEntityAirports = (state, payload) => {
  return {
    ...state,
    selectedEntityAirports:payload.airports,
    selectedEntityAirportsCount: payload.airportsCount,
    loading:false
  };
};

const addEntityAirport = (state, payload) => {
  return {
    ...state,
    selectedEntityAirports:payload.airports,
    selectedEntityAirportsCount: payload.airportsCount,
    loading:false
  };
};

const deleteEntityAirport = (state, payload) => {
  return {
    ...state,
    selectedEntityAirports:payload.airports,
    selectedEntityAirportsCount: payload.airportsCount,
    loading:false
  };
};

const getFromAirports = (state, payload) => {
  return {
    ...state,
    fromAirports:payload,
    loading:false
  };
};

const getToAirports = (state, payload) => {

  return {
    ...state,
    toAirports:payload,
    loading:false
  };
};


const airport = (state = initialState, action) => {
  const { type, payload, direction } = action;

  switch (type) {
    case AIRPORT_PROFILE_GET_ALL:
      return getProfileAirports(state, payload);
    case AIRPORT_PROFILE_ADDED:
      return addProfileAirport(state, payload);
    case AIRPORT_PROFILE_DELETED:
      return deleteProfileAirport(state, payload);
    case AIRPORT_ENTITY_ADDED:
      return addEntityAirport(state, payload);
    case AIRPORT_ENTITY_DELETED:
      return deleteEntityAirport(state, payload);
    case AIRPORT_ENTITY_GET_ALL:
      return getEntityAirports(state, payload);
    case AIRPORT_GET_TO:
      return getToAirports(state, payload);
    case AIRPORT_GET_FROM:
      return getFromAirports(state, payload);
    default:
      return state;
  }
};
export default airport;
