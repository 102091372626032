import React, {useState} from "react";

import Logo from "./Logo";
import Action from "./Action";
import Title from "./Title";

const VideoPlayer = ({ source }) => {
  const [alert, setLandAlert] = useState({"isOpen": false, "msg": "", "type": "error"})
  
    const handleLandAlert = (msg, type) => {
        setLandAlert({"isOpen": true, "msg": msg, "type": type});
        setTimeout(() => setLandAlert({"isOpen": false, "msg": "", "type": type}), 5000);
    }
  
  return (
    <>
      <video autoPlay playsInline loop muted>
        <source src={source} type="video/mp4" />
      </video>
      <Logo alert={alert} />
      <Title />
      <Action alertHandler={handleLandAlert} />
    </>
  );
};
export default VideoPlayer;
