import {REMOVE_LOADING, SET_LOADING} from '../types';

const initialState = {
    loading: false
};

const setLoading = state => ({
    ...state,
    loading: true
});

const removeLoading = state => ({
    ...state,
    loading: false
});

const loading =  function(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case SET_LOADING:
            return setLoading(state);
        case REMOVE_LOADING:
            return removeLoading(state);
        default:
            return state;
    }
}

export default loading;
