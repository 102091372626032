import React from 'react';
import {makeStyles} from '@material-ui/core';
import {AnonymousAppBar, AuthenticatedAppBar, Footer} from './components';

import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {connect} from "react-redux";
import {withRouter} from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.primary.contrastText,
    height: '100%'
  }
}));

function OracleLayout(props) {
  const classes = useStyles(props);
  const { children, withFooter, isAuthenticated, withHeader=true } = props;
  const header = isAuthenticated ? <AuthenticatedAppBar {...props}/> : <AnonymousAppBar {...props}/>
  return (
    <div className={classes.root}>
      {withHeader && header}
      {children}
      {withFooter && <Footer />}
    </div>
  );
}


function mapStateToProps({authState}) {
  return {
    user: authState.user,
    isAuthenticated:authState.isAuthenticated
  }
};

const mapDispatchToProps = {  };

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OracleLayout)));

