import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {createBrowserHistory} from 'history'
import jwtRefreshMiddleware from '../middleware/jwtRefreshMiddleware';
export const history = createBrowserHistory();

const initialState = {};
const middleware = [jwtRefreshMiddleware, thunk];


const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;