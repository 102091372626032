export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const TOGGLE_USER_DIALOG = 'TOGGLE_USER_DIALOG';
export const SELECT_USER = 'SELECT_USER';
export const SELECT_ALL_USERS = 'SELECT_ALL_USERS';

export const SELECT_BROWSING_TAB = 'SELECT_BROWSING_TAB';
export const SELECT_TAG = 'SELECT_TAG';
