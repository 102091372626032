const css = (theme) => ({
  appbar: {
    backgroundColor: '#fff !important',
    color: '#263238 !important'
  },
  logoImg: {
    width: '100%',
    maxHeight: '40px',
  },
  logoImgSm: {
    width: '100%',
    maxHeight: '30px',
  },
  logo: {
    display: { xs: 'none', md: 'flex' },
  },
  snsIcon: {
    color:'#263238',
    fontSize:'22px',
    "&:hover": {
      color: '#485D7C',
    },
  },
});
export default css;
