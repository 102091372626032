
export const TYPE_INSPIRATION = 'inspiration';
export const TYPE_RECOMMENDATION = 'recommendation';
export const TYPE_PLACE = 'place';
export const TYPE_EVENT = 'event';

export const STATE_PREVIEW = -1;
export const STATE_DELETED = 0;
export const STATE_NEW = 1;
export const STATE_REQ_TO_PUBLISH = 2;
export const STATE_MODIFIED = 3;
export const STATE_PUBLISHED = 4;

export const SCOPE_SELF         = 'self';
export const SCOPE_FLICKR       = 'flickr';
export const SCOPE_INSTAGRAM    = 'instagram';
export const SCOPE_GOOGLE       = 'google';
export const SCOPE_WIKIPEDIA    = 'wikipedia';

export const ROLE_GUEST         = 'ROLE_GUEST';
export const ROLE_REGISTERED    = 'ROLE_REGISTERED';
export const ROLE_AUTHOR        = 'ROLE_AUTHOR';
export const ROLE_SUPER         = 'ROLE_SUPER';

export const FILTERS = {
    'ALL' : 'ALL',
    'CHILDREN': 'CHILDREN',
    'FAVORITED': 'FAVORITED',
    'UPVOTED': 'UPVOTED',
    'MYWATCH': 'MYWATCH',
    'CREATED': 'CREATED',
    'FEATURED': 'FEATURED'
}
