import React, { useState, useRef } from "react";

const Action = ({ alertHandler }) => {
  const [showButton, setShowButton] = useState(true);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);


  const submitEmail = async () => {
    let email = inputRef.current.value;
    try {
      let response = await fetch(
        "https://waw-backend-staging.herokuapp.com/api/subscribe",
        {
          method: "POST",
          headers: new Headers({'content-type': 'application/json'}),
          body: JSON.stringify({ email }),
        }
      );
      let responseJson = await response.json();
      if (response.status === 200) {
        setError(null);
        alertHandler("Success", "success");
        setShowButton(true);
      } else {
        let error = responseJson.error;
        alertHandler(error, "error");
        setError(error.slice(2, error.length -2));
      }
    } catch (err) {
      console.log(err);
      alertHandler(err, "error");
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowButton(false);
  };

  const handleSubmitButton = (e) => {
    e.preventDefault();
      submitEmail();
  };

  return (
    <div className="actionContent">
      {showButton ? (
        <>
          <a href="#" onClick={(e) => handleLinkClick(e)} className="signLink">
            Receive notifications about the beta release
          </a>
        </>
      ) : (
        <div className="formContainer">
          <label className="formlabel">{error ? error : 'email'}</label>
          <input
            className={
              error
                ? "formelement forminput inputerror"
                : "formelement forminput"
            }
            type="text"
            ref={inputRef}
          />
          <button
            className="formelement formbtn"
            type="submit"
            onClick={(e) => handleSubmitButton(e)}
          >
            submit
          </button>
        </div>
      )}
    </div>
  );
};

export default Action;
