import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, withStyles} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import styles from './styles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";

const Sidebar = props => {
    const {classes, user, createEntity, createdEntity} = props;

    const [createOpen, setCreateOpen] = React.useState(false);
    const [canCreate, setCanCreate] = React.useState(false);
    const [type, setType] = React.useState('inspiration');
    const [createDisabled, setCreateDisabled] = React.useState(true);
    const [name, setName] = React.useState(null);

    if (createdEntity != null && canCreate) {
        window.location.reload();
        return <Redirect key={createdEntity.id} to={"/" + createdEntity.type + "/" + createdEntity.slug}/>
    }

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
    };

    const handleTypeChange = (event) => {
        const {target: {value},} = event;
        setType(typeof value === 'string' ? value.split(',') : value,);
    };

    const handleNameChange = (event) => {
        const {target: {value},} = event;
        if (value && typeof value === 'string' && value.length > 0) {
            setName(value);
            if (type) {
                setCanCreate(true);
                setCreateDisabled(false)
            }
        }
    };

    const handleCreate = () => {
        if (name && type && canCreate) {
            const entity = {name: name, type: type};
            createEntity(entity)
        }

    }

    return (
        <Fragment>
            <section className={classes.root}>
                <List component="div" disablePadding>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        onClick={handleCreateOpen}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountBoxIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Create"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/notifications/@' + user.username}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountBoxIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Notifications"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/@' + user.username + '/mywatch'}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Watched By Me"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/@' + user.username + '/created'}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Created By Me"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/@' + user.username + '/favorited'}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Favorited By Me"
                        />
                    </ListItem>

                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/followedby/@' + user.username}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Users Followed By Me"
                        />
                    </ListItem>

                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/following/@' + user.username}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Users Who Follow Me"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/myairports/@' + user.username}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="My Home Airports"
                        />
                    </ListItem>
                    <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={'/profile/myschedule/@' + user.username}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="My Schedule"
                        />
                    </ListItem>
                    {user && user.role === 'superadmin' && (
                        <ListItem
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLink}
                            to="/admin/users">
                            <ListItemIcon className={classes.listItemIcon}>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{primary: classes.listItemText}}
                                primary="Users"
                            />
                        </ListItem>
                    )}
                </List>
                <Divider className={classes.listDivider}/>
                <List
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            Support
                        </ListSubheader>
                    }>
                    <ListItem
                        className={classes.listItem}
                        component="a"
                        href="mailto:support@whyandwhere.com"
                        target="_blank">
                        <ListItemIcon className={classes.listItemIcon}>
                            <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Contact Us"
                        />
                    </ListItem>
                </List>
            </section>
            <Dialog open={createOpen} onClose={handleCreateClose}>
                <DialogTitle>Create New Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create new content based on the type.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="entity_name"
                        label="Name of your content"
                        type="name"
                        variant="standard"
                        fullWidth
                        onChange={handleNameChange}
                    />
                    <FormControl fullWidth style={{marginTop: '10px'}}>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={type}
                            label="Type"
                            onChange={handleTypeChange}
                        >
                            <MenuItem value="inspiration">Inspiration or Recommendation</MenuItem>
                            <MenuItem value="deal">Event</MenuItem>
                            <MenuItem value="place">Place</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateClose}>Cancel</Button>
                    <Button onClick={handleCreate} disabled={createDisabled}>Create</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const mapStateToProps = ({authState}) => ({
    user: authState.user
});
export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(Sidebar));
