export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const RESET_CONFIRM = 'RESET_CONFIRM';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const DONE_REFRESHING_TOKEN = 'DONE_REFRESHING_TOKEN';
export const GOOGLE_LOGIN_UNAVAILABLE = 'GOOGLE_LOGIN_UNAVAILABLE';
export const FACEBOOK_LOGIN_UNAVAILABLE = 'FACEBOOK_LOGIN_UNAVAILABLE';
